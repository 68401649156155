<!-- eslint-disable no-unused-vars -->
<script setup>
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import { useRouter } from 'vue-router'
// eslint-disable-next-line no-unused-vars
import { ref } from 'vue'
import { InitMsal, signIn2, apiCoreGet, signOut2, InitMsal3, SignIn3, GetUserName,acquireAndSetTokens } from '../../../msal/msalInterface'
import { callApi, getTokenUser } from '../../../msal/msalApi';


import { b2cEnvironmentSettings } from '../../../msal/b2cEnv'
// eslint-disable-next-line no-unused-vars
// import {testMyCfg} from '../../../msal/testcfg'
// import { debug } from 'console'
//import {coreMSALConfig} from '../../../msal/coreMSALConfig'
// const allLoggedIn = () =>
// {
//       const loginButton = this.$refs.loginButton;

//       // Set the background color of the login button to green
//       loginButton.style.backgroundColor = "green";
// }

let router = useRouter()

// eslint-disable-next-line no-unused-vars
const handleLogin = async () => {
  await InitMsal(b2cEnvironmentSettings,
    () => { console.log('all logged in!'); });
  signIn2();
};


// eslint-disable-next-line no-unused-vars
const coreGet = async (endpoint, params) => {
  console.log("Executing coreGet ....");
  let result = await apiCoreGet(endpoint, params);
  console.log("coreGet result ==>");
  console.log(result);
};


// eslint-disable-next-line no-unused-vars
const signOut = async () => {
  signOut2();
}

const myTest = async () => {

  // let myVal = coreMSALConfig();
  // console.log(myVal);
  await InitMsal3();
  //await SignIn3(() => {console.log('the app has been singed in.');});
  await SignIn3(fnAllSignedIn);
}

 const fnAllSignedIn = async () => {
  await acquireAndSetTokens()

   router.push('/')  

  console.log("Executing allSignedIn in the log in page...");
  let userName = GetUserName();
  console.log(`${userName} has been logged in`)
}
</script>

<template>
  <div class="login-container">

    <div class="row">
      <div class="col-12 col-md-6 col-sm-3">
        <img class="banner" src="../../../../src/assets/images/LOGO.png" alt="" width="500">
        <p class="h4 mt-4">PowerFul Technologies for a Better Life</p>
      </div>
      <div class="col-12 col-md-3 col-sm-3 mt-2">
        <form>
          <p class=" h4 login-text">
            The Intelligent Technologies.ai admin portal requires a secure login via your Microsoft account
          </p>
          <div class="d-grid gap-2">
            <button class="btn btn-primary fw-bold mt-5" id="login" type="button" @click="myTest">
              <img class="text-white" src="https://cdn-icons-png.flaticon.com/512/5593/5593418.png" alt="" width="16">
              Log In
            </button>
            <!--<button type="button" @click="llamar">Call Protected</button>-->
          </div>
          <!--<button id="login" type="button"  @click="handleLogin">Login</button>
                    <button id="signOut" type="button" @click="signOut">Logout</button>
                    <button type = "button" @click="coreGet('Hello')">Call Protected</button>-->

        </form>
      </div>
    </div>
  </div>

  <footer class="copy text-center mt-5">
    Copyright 2023 WeightLossTechnologies.ai All Rights Reserved.
  </footer>
</template>

<style>
.login-container {
  text-align: center;
  margin-top: 16rem;
  margin-left: 20rem;
}

@media (max-width: 768px) {
  .login-container {
    margin-top: 0rem;
    margin-left: 0rem;
  }
}

@media (max-width: 576px) {

  .login-text {
    font-size: 15px;
  }
  .banner{
    width: 100%;
  }
}

.login-text {
  text-align: justify;
}



footer.copy {
  margin-top: 25rem !important;
  padding: 2.5rem 0 2.5rem 0;
  background-color: rgba(197, 197, 197, 0.452);
}
</style>