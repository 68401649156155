const b2cEnvironmentSettings = {
    authority: "weightlossdirect.b2clogin.com",
    domain: "weightlossdirect.onmicrosoft.com",
    redirectUri: "http://localhost:8080",
    signUpSignIn: "B2C_1_susi",
    editProfile: "B2C_1_edit_profile",
    resources: 
        [
            {
                name: "mobileMSAL",
                applicationID: "8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c",
                scopes: ["https://weightlossdirect.onmicrosoft.com/8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c/task.read","https://weightlossdirect.onmicrosoft.com/8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c/task.write"],
                apiUri: "https://weightlossmobiledev.azurewebsites.net/"

            },
            {
                name: "coreMSALConfig",
                applicationID: "8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c",
                scopes: ["https://weightlossdirect.onmicrosoft.com/8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c/task.read","https://weightlossdirect.onmicrosoft.com/8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c/task.write"],
                apiUri: "https://wld-core-api.azurewebsites.net/"
            }
        ]
    
};



module.exports = {b2cEnvironmentSettings};


